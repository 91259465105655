
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import useFeatures from '@/hooks/useFeatures';
import useNavigate from '@/hooks/useNavigate';
import { Article } from '@/services/api/modules/article.types';
import { Resource } from '@/services/api/modules/workspaces';

import Mail from './Mail.vue';

export default defineComponent({
  components: {
    Mail,
  },

  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
    resource: {
      type: Object as () => Resource,
      required: false,
    },
    docked: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['close', 'dock'],

  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const mailModal = ref();
    const clipLoading = ref(false);

    const isClipped = computed(() =>
      store.state.collections.clippings.includes(props.article.id),
    );

    const dockIcon = computed(() =>
      props.docked ? 'crop_7_5' : 'crop_portrait',
    );

    const handlePrint = () => {
      window.print();
    };

    const handleMail = () => {
      mailModal.value?.show();
    };

    const canSend = computed(() => props.article.canSend);

    const handleClip = () => {
      clipLoading.value = true;

      const dispatchName = isClipped.value
        ? 'collections/removeClippings'
        : 'collections/addClippings';

      store
        .dispatch(dispatchName, {
          origin: props.article.source,
          items: [props.article.id],
        })
        .finally(() => {
          clipLoading.value = false;
        });
    };

    const handleClose = () => {
      emit('close');
    };

    const handleBack = () => {
      router.go(-1);
    };

    const handleDock = () => {
      emit('dock');
    };

    return {
      mailModal,
      canSend,
      clipLoading,
      isClipped,
      dockIcon,
      handlePrint,
      handleMail,
      handleClip,
      handleClose,
      handleBack,
      handleDock,
      ...useFeatures(),
      ...useNavigate(handleClose),
    };
  },
});
